document.addEventListener('DOMContentLoaded', () => {

    $(document).on('click','a.page-scroll',function(event){
        event.preventDefault();
        var $anchor=$(this);
        $('html,body').animate({
        scrollTop: ($($anchor.attr('href')).offset().top-0) // top-58 : take account of height of navbar if navbar stay on top
        },1500);
    });

});