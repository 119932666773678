document.addEventListener('DOMContentLoaded', () => {

    const clickCardCategory = (clickedCardCategory) => {
        const category = clickedCardCategory.dataset.realisations;

        let realisationsItems = document.querySelectorAll('.realisations-item');
        if (realisationsItems){
            realisationsItems.forEach((realisationItem) => {
                realisationItem.classList.remove("hide-me");
                if (realisationItem.dataset.realisations != category) {
                    realisationItem.classList.add("hide-me");
                }
            });
        }
            
    }

    let cardCategories = document.querySelectorAll('.card-category');
    if (cardCategories){
        cardCategories.forEach((cardCategory) => {
            cardCategory.addEventListener('click', (event) => {
            clickCardCategory(cardCategory);
        });
        });
    }
    
});